export default class ApprovalCharge {
  affiliationIds: Array<string>;
  approvalA: string;
  approvalB: string;
  approvalC: string;
  approvalD: string;
  constructor(
    affiliationIds: Array<string>,
    approvalA: string,
    approvalB: string,
    approvalC: string,
    approvalD: string
  ) {
    this.affiliationIds = affiliationIds;
    this.approvalA = approvalA;
    this.approvalB = approvalB;
    this.approvalC = approvalC;
    this.approvalD = approvalD;
  }
}
